import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlider } from 'vuetify/lib/components/VSlider';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"constructor__main"},[_c('div',{staticStyle:{"margin-bottom":"15px"}},[_c(VBtn,{attrs:{"color":"primary","outlined":"","small":""},on:{"click":_vm.closeSettings}},[_c(VIcon,[_vm._v("mdi-chevron-left")]),_vm._v("Back to contact settings")],1)],1),_c('div',{staticClass:"form"},[_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('constructor["Button Arrangement"]')))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('constructor["Choose a style for the social links"]'))+" ")]),_c('div',{staticClass:"socials-style"},[_c('div',{staticClass:"\n                                      socials-style__item\n                                      socials-style__item_default\n                                    ",class:{
              active: _vm.content.style === 'default',
            },on:{"click":function($event){return _vm.changeStyleContact(_vm.content, 'default')}}},[_c('div'),_c('div'),_c('div')]),_c('div',{staticClass:"\n                                      socials-style__item\n                                      socials-style__item_tile\n                                    ",class:{
              active: _vm.content.style === 'tile',
            },on:{"click":function($event){return _vm.changeStyleContact(_vm.content, 'tile')}}},[_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div')])])]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('constructor["Button Radius"]'))+" "),_c('strong',[_vm._v(_vm._s(_vm.content.radius)+"px")])]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('constructor["Choose your social links style"]'))+" ")]),_c('div',{staticClass:"slider"},[_c(VSlider,{attrs:{"max":32,"min":0},model:{value:(_vm.content.radius),callback:function ($$v) {_vm.$set(_vm.content, "radius", $$v)},expression:"content.radius"}})],1)]),(_vm.content.style === 'default')?_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Button Width "),_c('strong',[_vm._v(_vm._s(_vm.content.width)+"%")])]),_c('div',{staticClass:"slider"},[_c(VSlider,{attrs:{"max":100,"min":0},model:{value:(_vm.content.width),callback:function ($$v) {_vm.$set(_vm.content, "width", $$v)},expression:"content.width"}})],1)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }